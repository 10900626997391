import {requestSubmit} from '@github-ui/form-utils'
import {controller, target} from '@github/catalyst'
import '@oddbird/popover-polyfill'
import type IncludeFragmentElement from '@github/include-fragment-element'

export type ItemActivatedEvent = {
  item: Element
  checked: boolean
}

declare global {
  interface HTMLElementEventMap {
    itemActivated: CustomEvent<ItemActivatedEvent>
  }
}

@controller
class PagesJekyllConfig extends HTMLElement {
  @target includeFragment: IncludeFragmentElement
  @target selected: HTMLElement
  selectedBranch: string
  selectedFolder: string

  connectedCallback() {
    // eslint-disable-next-line custom-elements/no-dom-traversal-in-connectedcallback
    const itemLabel = this.querySelector('[aria-checked=true] .ActionListItem-label')
    // eslint-disable-next-line custom-elements/no-dom-traversal-in-connectedcallback
    const selectedLabel = itemLabel?.textContent?.trim() || ''
    this.selectedBranch = selectedLabel

    this.selectedFolder = this.getAttribute('data-initial-subdir') || '/'
    this.setBranchAndPath()
    this.setSubmitButton()
  }

  selectFolder(item: Element | null) {
    if (item) {
      const detail = 'detail' in item && typeof item.detail === 'object' ? item.detail : {}
      // get the dataset field from the item that is in the detail
      if (!detail) return
      const activeItem = 'item' in detail && detail.item instanceof Element ? detail.item : null
      if (!activeItem) return
      // eslint-disable-next-line github/no-dataset
      const dataset = 'dataset' in activeItem && activeItem.dataset instanceof DOMStringMap ? activeItem.dataset : {}
      this.selectedFolder = dataset.name as string
      this.setSubmitButton()
    }
  }

  selectBranch(item: Element | null) {
    if (item) {
      const detail = 'detail' in item && typeof item.detail === 'object' ? item.detail : {}
      if (!detail) return
      const itemLabel = this.querySelector('[aria-checked=true] .ActionListItem-label')
      const selectedLabel = itemLabel?.textContent?.trim() || ''
      this.selectedBranch = selectedLabel
      this.setBranchAndPath()
      this.setSubmitButton()
    }
  }

  setBranchAndPath() {
    const sourceDirSelector = this.querySelector<HTMLSelectElement>('.js-jekyll-source-dir')
    const buttonVisual = this.querySelector<HTMLSelectElement>('.Button-leadingVisual')
    if (this.selectedBranch === 'None') {
      sourceDirSelector?.setAttribute('hidden', '')
      buttonVisual?.setAttribute('hidden', '')
    } else {
      sourceDirSelector?.removeAttribute('hidden')
      buttonVisual?.removeAttribute('hidden')
    }
  }

  setSubmitButton(): void {
    const submitButton = this.querySelector<HTMLButtonElement>('.js-jekyll-submit')!
    if (submitButton.getAttribute('data-always-enabled') === 'true') return
    const initialBranch = this.getAttribute('data-initial-source')
    const initialFolder = this.getAttribute('data-initial-subdir')
    const disabled =
      (initialBranch === this.selectedBranch || (initialBranch === 'gh-pages' && this.selectedBranch === 'None')) &&
      (initialFolder === this.selectedFolder || (!initialFolder && this.selectedFolder === '/'))
    if (disabled) {
      submitButton?.setAttribute('disabled', '')
    } else {
      submitButton?.removeAttribute('disabled')
    }
  }

  submitForm() {
    const form = (this.closest('form') || this.querySelector('form')) as HTMLFormElement
    // append input to form
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = 'source'
    input.value = this.selectedBranch
    form.appendChild(input)

    // append the subdir input to form
    const subdirInput = document.createElement('input')
    subdirInput.type = 'hidden'
    subdirInput.name = 'source_dir'
    subdirInput.value = this.selectedFolder
    form.appendChild(subdirInput)
    requestSubmit(form)
  }
}

if (!window.customElements.get('pages-jekyll-config')) {
  window.customElements.define('pages-jekyll-config', PagesJekyllConfig)
}

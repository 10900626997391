// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {onInput} from './../onfocus'

on('click', '.js-repo-change-visibility-button', function ({currentTarget}) {
  const dialogId = currentTarget.getAttribute('data-show-dialog-id')!
  const modal = document.getElementById(dialogId) as HTMLElement

  modal.addEventListener(
    'cancel',
    () => {
      resetVisibilityModal(modal)
    },
    {once: true},
  )
  modal.addEventListener(
    'close',
    () => {
      resetVisibilityModal(modal)
    },
    {once: true},
  )
})

on('click', '.js-repo-delete-button', function ({currentTarget}) {
  const dialogId = currentTarget.getAttribute('data-show-dialog-id')!
  const modal = document.getElementById(dialogId) as HTMLElement

  modal.addEventListener(
    'cancel',
    () => {
      resetDeleteModal()
    },
    {once: true},
  )
  modal.addEventListener(
    'close',
    () => {
      resetDeleteModal()
    },
    {once: true},
  )
})

on('click', '.js-repo-visibility-proceed-button', function ({currentTarget}) {
  const button = currentTarget as HTMLButtonElement
  const nextStage = button.getAttribute('data-next-stage')!
  const newVisibility = button.getAttribute('data-new-visibility')!

  if (nextStage && newVisibility) {
    transitionVisibilityModalTo(newVisibility, nextStage)
  }
})

on('click', '.js-repo-delete-proceed-button', function ({currentTarget}) {
  const button = currentTarget as HTMLButtonElement
  const nextStage = button.getAttribute('data-next-stage')!

  if (nextStage) {
    transitionDeleteModalTo(nextStage)
  }
})

onInput('.js-repo-visibility-proceed-confirmation', function ({currentTarget}) {
  const input = currentTarget as HTMLInputElement
  const numberOfStars = parseInt(input.getAttribute('data-number-of-stars')!, 10)
  const newVisibility = input.getAttribute('data-new-visibility')!
  const repoNwo = input.getAttribute('data-repo-nwo')!

  if (newVisibility && repoNwo) {
    const button = document.getElementById(`repo-visibility-proceed-button-${newVisibility}`) as HTMLButtonElement

    let proceed = false

    if (newVisibility === 'public') {
      proceed = input.value === repoNwo
    } else {
      proceed = numberOfStars === parseInt(input.value.replace(/[^0-9]+/g, ''), 10)
    }

    if (button && proceed) {
      const form = input.closest('form') as HTMLFormElement

      if (form) {
        addHiddenFormInput(form, 'visibility', newVisibility)
        addHiddenFormInput(form, 'verify', repoNwo)
        button.disabled = false
      }
    } else {
      button.disabled = true
    }
  }
})

onInput('.js-repo-delete-proceed-confirmation', function ({currentTarget}) {
  const input = currentTarget as HTMLInputElement
  const repoNwo = input.getAttribute('data-repo-nwo')!

  if (repoNwo) {
    const button = document.getElementById(`repo-delete-proceed-button`) as HTMLButtonElement
    const proceed = input.value === repoNwo

    if (button && proceed) {
      const form = input.closest('form') as HTMLFormElement

      if (form) {
        addHiddenFormInput(form, 'verify', repoNwo)
        button.disabled = false
      }
    } else {
      button.disabled = true
    }
  }
})

function resetVisibilityModal(modal: HTMLElement) {
  const newVisibility = modal.getAttribute('data-new-visibility')!

  if (newVisibility) {
    transitionVisibilityModalTo(newVisibility, '1')
  }
}

function resetDeleteModal() {
  transitionDeleteModalTo('1')
}

function addHiddenFormInput(form: HTMLFormElement, name: string, value: string) {
  const newInput = document.createElement('input')
  newInput.setAttribute('type', 'hidden')
  newInput.setAttribute('name', name)
  newInput.setAttribute('value', value)

  form.appendChild(newInput)
}

function transitionVisibilityModalTo(newVisibility: string, stage: string) {
  const replacementWarningTemplate = document.getElementById(
    `change-repository-visibility-${newVisibility}-step-${stage}`,
  ) as HTMLTemplateElement
  const replacementButtonTemplate = document.getElementById(
    `proceed-button-visibility-${newVisibility}-step-${stage}`,
  ) as HTMLTemplateElement

  if (replacementWarningTemplate && replacementButtonTemplate) {
    const replacementButtonTemplateClone = replacementButtonTemplate.content.cloneNode(true)
    const replacementWarningTemplateClone = replacementWarningTemplate.content.cloneNode(true)

    const warningContainer = document.getElementById(
      `repo-visibility-warning-${newVisibility}-container`,
    ) as HTMLDivElement
    const existingButtonContainer = document.getElementById(
      `repo-visibility-proceed-button-${newVisibility}-container`,
    ) as HTMLDivElement

    if (warningContainer && existingButtonContainer) {
      warningContainer.replaceWith(replacementWarningTemplateClone)
      existingButtonContainer.replaceWith(replacementButtonTemplateClone)
    }

    const warningContent = document.querySelector(
      `[aria-label="Effects of making this repository ${newVisibility}"]`,
    ) as HTMLDivElement
    warningContent?.focus()
  }
}

function transitionDeleteModalTo(stage: string) {
  const replacementWarningTemplate = document.getElementById(`delete-repository-step-${stage}`) as HTMLTemplateElement
  const replacementButtonTemplate = document.getElementById(
    `proceed-button-delete-step-${stage}`,
  ) as HTMLTemplateElement

  if (replacementWarningTemplate && replacementButtonTemplate) {
    const replacementButtonTemplateClone = replacementButtonTemplate.content.cloneNode(true)
    const replacementWarningTemplateClone = replacementWarningTemplate.content.cloneNode(true)

    const warningContainer = document.getElementById(`repo-delete-warning-container`) as HTMLDivElement
    const existingButtonContainer = document.getElementById(`repo-delete-proceed-button-container`) as HTMLDivElement

    if (warningContainer && existingButtonContainer) {
      warningContainer.textContent = ''
      existingButtonContainer.textContent = ''
      warningContainer.appendChild(replacementWarningTemplateClone)
      existingButtonContainer.appendChild(replacementButtonTemplateClone)
    }
  }

  const warningContent = document.querySelector('[aria-label="Effects of deleting this repository"]') as HTMLDivElement
  warningContent?.focus()
}

import {remoteForm} from '@github/remote-form'

remoteForm('.js-verify-ssh-key', async function (form, wants) {
  await wants.html()

  const parent = form.closest<HTMLElement>('li')!
  parent.querySelector<HTMLElement>('.js-unverified-user-key-notice')!.remove()
  parent.querySelector<HTMLElement>('.js-user-key-icon')!.classList.remove('color-fg-danger')

  form.remove()
})

// Leave a collaborated repository
remoteForm('.js-leave-collaborated-repo', async function (form, wants) {
  await wants.html()
  const id = form.getAttribute('data-repo-id')!
  const ownerLogin = form.getAttribute('data-owner-login')!
  document.querySelector<HTMLElement>(`.js-collab-repo[data-repo-id='${id}']`)!.remove()
  if (!document.querySelector('.js-collab-repo')) {
    document.querySelector<HTMLElement>('.js-collaborated-repos-empty')!.hidden = false
    document.querySelector<HTMLElement>('.js-collaborated-repos')!.hidden = true
  }

  const ownerRepos = document.querySelector(`.js-collab-repo-owner[data-owner-login='${ownerLogin}']`)
  if (ownerRepos && !document.querySelector(`.js-collab-repo[data-owner-login='${ownerLogin}']`)) {
    ownerRepos.remove()
  }
})

// all other status-indicator elements.
function setIndicator(target: Element, className: string) {
  for (const statusIndicator of document.querySelectorAll('.js-setting-toggle .js-status-indicator')) {
    statusIndicator.classList.remove('status-indicator-success', 'status-indicator-loading', 'status-indicator-failed')
  }
  target.classList.add(className)
}

// Auto-subscribe checkbox toggle
remoteForm('.js-setting-toggle', async function (form, wants) {
  const target = form.querySelector<HTMLElement>('.js-status-indicator')!
  setIndicator(target, 'status-indicator-loading')
  try {
    await wants.text()
    setIndicator(target, 'status-indicator-success')
  } catch (error) {
    setIndicator(target, 'status-indicator-failed')
  }
})

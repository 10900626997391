// PATs v2: editing and creation

// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {changeValue} from '@github-ui/form-utils'

import {toggleMetadataSelectionMenu} from './integrations'
import {PermissionType, RepoSelectionType, updateElText} from './personal-access-tokens'
import {onInput} from '../onfocus'
import {updatePermissionSummary, updateRepositoryCount, updateExpiration} from './personal-access-token-summary'

function resetPermissions() {
  const selector = `[id^=integration_permission_][data-permission=${RepoSelectionType.None}]`
  const inputs = document.querySelectorAll<HTMLInputElement>(selector)

  for (const input of inputs) {
    changeValue(input, true)
  }

  toggleMetadataSelectionMenu()
  setInitialValues()
}

function toggleRepoPermissionVisibility() {
  const selectionType = document.querySelector<HTMLInputElement>('.js-installation-repositories-radio:checked')!.value
  const permissionSection = document.querySelector<HTMLElement>(
    '.js-integration-permissions-selector section[data-repository-permissions]',
  )!

  if (selectionType === RepoSelectionType.None) {
    permissionSection.hidden = true
    updateElText(`#token-summary [data-token-summary="repository-permission-count"]`, String(0))
  } else {
    permissionSection.hidden = false
    updatePermissionSummary('repository')
  }
}

function displayGrantForm() {
  const grantForm = document.querySelector<HTMLElement>('#js-grant-form')!
  const grantSummary = document.querySelector<HTMLElement>('#js-grant-summary')!
  grantSummary.hidden = true
  grantForm.hidden = false

  const formActions = document.querySelectorAll<HTMLElement>('.js-grant-form-action')
  for (const action of formActions) {
    action.hidden = false
  }

  const summaryActions = document.querySelectorAll<HTMLElement>('.js-grant-summary-action')
  for (const action of summaryActions) {
    action.hidden = true
  }
}

function displayGrantSummary(event: Event) {
  const target = event.currentTarget as HTMLElement

  if (target.classList.contains('js-grant-reload')) {
    return window.location.reload()
  }

  const grantForm = document.querySelector<HTMLElement>('#js-grant-form')!
  const grantSummary = document.querySelector<HTMLElement>('#js-grant-summary')!
  grantForm.hidden = true
  grantSummary.hidden = false

  const summaryActions = document.querySelectorAll<HTMLElement>('.js-grant-summary-action')
  for (const action of summaryActions) {
    action.hidden = false
  }

  const formActions = document.querySelectorAll<HTMLElement>('.js-grant-form-action')
  for (const action of formActions) {
    action.hidden = true
  }
}

function displayTokenForm() {
  const displayFields = document.querySelectorAll<HTMLElement>('.js-token-display-field')
  for (const field of displayFields) {
    field.hidden = true
  }

  const formFields = document.querySelectorAll<HTMLElement>('.js-token-form-field')
  for (const field of formFields) {
    field.hidden = false
  }
}

function hideTokenForm() {
  const formFields = document.querySelectorAll<HTMLElement>('.js-token-form-field')
  for (const field of formFields) {
    field.hidden = true
  }

  const displayFields = document.querySelectorAll<HTMLElement>('.js-token-display-field')
  for (const field of displayFields) {
    field.hidden = false
  }
}

function setInitialValues() {
  for (const permissionType of Object.values(PermissionType)) {
    updatePermissionSummary(permissionType)
  }
  updateRepositoryCount()

  // set initial state of collapsible containers to closed
  // includes permission lists and summaries
  for (const details of document.querySelectorAll<HTMLDivElement>('.js-collapsible-container')) {
    details.removeAttribute('open')
  }
}

function validateName() {
  const form = document.querySelector<HTMLFormElement>('.js-user-programmatic-access-form')!
  const nameInput = form.querySelector<HTMLInputElement>('.js-token-name')!
  const name = nameInput.value.trim()

  const valid = !nameInput.classList.contains('is-autocheck-errored') && name.length > 0

  const submit = form.querySelector<HTMLButtonElement>('button[type=submit]')!
  submit.disabled = !valid
}

// When the user is filling in the name of their token
onInput('.js-token-name-auto-check', validateName)

observe('.js-user-programmatic-access-form .js-integrations-install-repo-selection', el => {
  // observes when repository selections are added to the DOM
  // handles the case when user toggles back to select repositories displaying previous selections
  const selectedRepoListObserver = new MutationObserver(updateRepositoryCount)
  // observe subtree from element that is never removed from the dom to catch all changes
  selectedRepoListObserver.observe(el, {childList: true, subtree: true})
})

// observes when permissions sections are changed (load, repo selection changes, target changes)
observe('.js-integration-permissions-selector', setInitialValues)

on('change', '.js-permissions-selector [id^=integration_permission_]', ({currentTarget}) => {
  const permissionType = currentTarget.getAttribute('data-resource-parent')!
  updatePermissionSummary(permissionType)
})
on('change', '.js-user-programmatic-access-form .js-installation-repositories-radio', () => {
  resetPermissions()
  toggleRepoPermissionVisibility()
})
on('change', '.js-user-programmatic-access-form .js-default-token-expiration-select', updateExpiration)
on('change', '.js-user-programmatic-access-form #user_programmatic_access_custom_expires_at', updateExpiration)

on('click', '#js-token-action-edit', displayTokenForm)
on('click', '#js-token-action-cancel', hideTokenForm)
on('click', '#js-token-action-save', function ({currentTarget}) {
  currentTarget.classList.add('disabled')
  currentTarget.textContent = 'Updating'
})

on('click', '.js-toggle-grant-form', displayGrantForm)
on('click', '.js-toggle-grant-summary', displayGrantSummary)

// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

on('change', '.js-default-token-expiration-select', function (event) {
  const selectMenu = event.target as HTMLSelectElement
  const pickerWrapper = document.getElementById('custom-expires-wrapper') as HTMLElement
  const picker = document.querySelector('#custom-expires-wrapper .form-select') as HTMLInputElement
  const formElementWrapper = document.getElementById('token-expiration') as HTMLElement
  const expirationHelperNoteWrapper = document.getElementById('expiration-helper-note') as HTMLElement
  const expirationWarning = document.getElementById('no-expiration-warning') as HTMLElement
  const expirationNoteContent = expirationHelperNoteWrapper.querySelector('.note') as HTMLElement

  if (selectMenu && picker) {
    if (selectMenu.value === 'custom') {
      expirationHelperNoteWrapper.setAttribute('hidden', 'hidden')
      expirationWarning.setAttribute('hidden', 'hidden')
      pickerWrapper.removeAttribute('hidden')
    } else {
      pickerWrapper.setAttribute('hidden', 'hidden')
      picker.value = ''
      expirationHelperNoteWrapper.removeAttribute('hidden')
      formElementWrapper.classList.remove('errored')

      if (selectMenu.value === 'none') {
        expirationWarning.removeAttribute('hidden')
      } else {
        expirationWarning.setAttribute('hidden', 'hidden')
      }

      const selectedIndex = selectMenu.selectedIndex
      const selectedOption = selectMenu.children[selectedIndex]!
      const humanTextAttr = selectedOption.getAttribute('data-human-date')

      if (humanTextAttr) {
        expirationNoteContent.textContent = humanTextAttr
      }
    }
  }
})
